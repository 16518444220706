import React, { ReactNode } from 'react';

import { container } from '../../styles/themes';
import { classNames } from '../../utils/style';

import {
  fontBody,
  fontHeading1,
} from 'libs/growth-platform-brand-system-v2/src/styles/typographyNew';

export interface HeroThankYouProps {
  className?: string;
  title: string;
  subtitle: string;
  ctaButtons?: ReactNode[];
}
export const HeroThankYou = ({
  className = '',
  title,
  subtitle,
  ctaButtons
}: HeroThankYouProps) => {
  return (
    <>
      <section
        id="hero-thank-you"
        className={classNames(
          container,
          'hero-thank-you',
          'overflow-hidden pt-0 md:pt-[20px]',
        )}
      >
        <div
          className={classNames(
            'flex flex-col-reverse md:flex-row',
            'px-20 pt-40 md:p-80 bg-heroVideo rounded-[40px] justify-center items-center',
            'text-center',
          )}
        >
          <div
            className={classNames(
              `flex flex-col justify-center md:items-center`,
              'gap-32',
            )}
          >
            <h1
              className={classNames('text-neutral-301 max-w-610', fontHeading1)}
            >
              {title}
            </h1>
            <div className={classNames('max-w-610', 'text-center', fontBody)}>
              {subtitle && subtitle}
            </div>
            {ctaButtons && ctaButtons.length > 0 && (
                <div className={classNames('flex flex-col gap-12 md:flex-row')}>
                  {ctaButtons && ctaButtons}
                </div>
              )}
          </div>
        </div>
      </section>
    </>
  );
};
