import React, { ReactNode } from 'react';
import { Rating } from '../../components/Rating';
import {
  fontBody,
  fontCaption,
  fontHeading1,
} from '../../styles/typographyNew';
import { classNames } from '../../utils/style';

export interface HeroHomeProps {
  title: string;
  subtitle: string;
  buttons?: ReactNode[];
  rating?: number;
  ratingText?: string;
}

export const HeroHome = ({
  title,
  subtitle,
  buttons,
  rating,
  ratingText,
}: HeroHomeProps) => {
  return (
    <section className={classNames('HeroHome', 'xl:pt-[12px]')}>
      <div
        className={classNames(
          'mx-16 lg:mx-[100px] xxl:mx-auto',
          'px-[35px] pt-[64px] md:pt-[80px]',
          'xl:max-w-[1240px] w-auto',
          'relative',
        )}
      >
        <div
          className={classNames(
            'absolute z-0 top-0 left-0 right-0 min-h-[704px] md:min-h-[585px] bg-hero-gradient',
            'rounded-[40px]',
          )}
        />
        <div className="relative mx-auto flex flex-col items-center text-center z-[1]">
          <div className="HeroHomeTitle_Subtitle md:mx-auto md:max-w-[800px] w-full">
            <h1
              className={classNames(
                'HeroHomeTitle',
                fontHeading1,
                'text-navy-200 mb-16',
              )}
            >
              {title}
            </h1>
            <h2
              className={classNames(
                'HeroHomeSubtitle',
                fontBody,
                'mb-24 text-navy-80',
              )}
            >
              {subtitle}
            </h2>
          </div>
          <div className="flex flex-col justify-center items-center gap-24 mb-[48px] md:mb-[55px] w-full">
            {rating && (
              <div
                className={classNames(
                  'order-2 md:order-1 lg:mb-0 text-navy-80',
                  fontCaption,
                )}
              >
                <Rating rating={rating} /> {ratingText}
              </div>
            )}
            {buttons && buttons.length > 0 && (
              <div
                className={classNames(
                  'Buttons',
                  'flex flex-col md:flex-row flex-wrap',
                  'order-1 md:order-2',
                  'justify-center',
                  'gap-12',
                  ' w-full',
                )}
              >
                {buttons.map((button, index) => (
                  <div key={index} className="w-full md:w-auto">
                    {button}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
