import { ButtonV2 } from 'libs/growth-platform-brand-system-v2/src/components/ButtonV2';
import { MosaicItem } from 'libs/growth-platform-brand-system-v2/src/components/Mosaic';
import {
  getGoogleAnalyticsId,
  track,
} from 'libs/growth-platform-brand-system-v2/src/utils/tracking';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import { createSchema, morphism } from 'morphism';
import React from 'react';
import { TrackingVideo } from '../../components/TrackingVideo';
import { schemaDatoButton } from '../schemaDatoButton';
import { schemaImage } from './schemaImage';

export const schemaMosaicItem = createSchema<MosaicItem, any>({
  type: {
    path: '__typename',
    fn: (propertyValue) => {
      if (propertyValue === 'DatoCmsNbHeroMosaicButtoncard')
        return 'buttonCard';
      if (propertyValue === 'DatoCmsNbHeroMosaicVideo') return 'video';
      return 'image';
    },
  },
  onClickVideoThumbnail: {
    path: [],
    fn: () => {
      return () =>
        track({
          gtm: {
            event: 'Toggle Interaction',
            properties: {
              componentName: 'HeroMosaic',
              toggleLabel: 'NA/button open video modal',
              gaClientID: getGoogleAnalyticsId(),
            },
          },
        });
    },
  },
  image: {
    path: 'image',
    fn: (propertyValue: any) => {
      if (propertyValue) {
        return morphism(schemaImage, propertyValue);
      }
      return;
    },
  },

  video: {
    path: 'video',
    fn: (propertyValue: any) => {
      if (propertyValue) {
        return <TrackingVideo url={propertyValue} />;
      }
      return;
    },
  },
  button: {
    path: ['ctaButton', 'locale'],
    fn: ({ ctaButton, locale }: any) => {
      if (ctaButton?.[0]) {
        const cta = morphism(schemaDatoButton, { ...ctaButton?.[0], locale });
        return (
          <ButtonTracking
            component={ButtonV2}
            {...cta}
            labelTracking={cta.title}
          />
        );
      }
      return;
    },
  },
});
